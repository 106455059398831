// src/pages/Home.tsx
import React, {ChangeEvent, useState} from 'react';
import axios from 'axios';
import './Home.css';

interface SuggestionResponse {
  suggestions: [string, string][]; // 定义建议回复的类型为一个包含两个字符串的数组
}

const Home: React.FC = () => {
  const [image, setImage] = useState<string | null>(null);
  const [chatText, setChatText] = useState<any[]>([]);
  const [isScanning, setIsScanning] = useState<boolean>(false);
  const [suggestions, setSuggestions] = useState<[string, string][]>([]);
  const [isGenerating, setIsGenerating] = useState<boolean>(false); // 新的状态用于控制按钮禁用
  const [chatScene, setChatScene] = useState('');
  const [relationship, setRelationship] = useState('');
  const [userMessage, setUserMessage] = useState('');


  const handleImageUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setImage(URL.createObjectURL(file));
      scanImage(file);
    }
  };
  const generateSuggestions = async () => {
    setIsGenerating(true);
    setSuggestions([]);
    try {
      const response = await axios.post<SuggestionResponse>('https://api.geeklamp.cn/reply', {
        conversion: chatText,
        relationship: relationship,
        scene: chatScene,
        keywords: userMessage,
      });
      // @ts-ignore
      setSuggestions(response.data);
    } catch (error) {
      console.error('Error generating suggestions:', error);
    } finally {
      setIsGenerating(false);
    }
  };

  const scanImage = async (file: File) => {
    setIsScanning(true);
    setSuggestions([]);
    setChatText([]);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('https://api.geeklamp.cn/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        withCredentials: true,
      });
      setChatText(response.data);
    } catch (error) {
      console.error('Error scanning image:', error);
      alert('Error scanning image. Please try again. ' + error);
    } finally {
      setIsScanning(false);
    }
  };

  return (
    <div className="App">
      <h1>高情商回复</h1>
      <i>不确定如何回复重要的消息？上传你的聊天截图，情商大师将为你提供专业建议，助你轻松应对每一个关键时刻！</i>
      <p></p>
      <input type="file" accept="image/*" onChange={handleImageUpload}/>
      {image && (
        <div className="image-container">
          <img src={image} alt="Uploaded"/>
          {isScanning && <div className="scanning-overlay"></div>}
        </div>
      )}
      <div className="chat-display">
        {chatText.map((text, index) => (
          <div key={index} className={`chat-bubble ${text.role === "friend" ? "left" : "right"}`}>
            {text.content}
          </div>
        ))}
      </div>
      {chatText.length > 0 && (
        <>
          <div className="form-container">
            <label>
              <select value={chatScene} onChange={(e) => setChatScene(e.target.value)}>
                <option value="">聊天场景</option>
                <option value="工作">工作</option>
                <option value="恋爱">恋爱</option>
                <option value="朋友">朋友</option>
              </select>
            </label>
            <label>
              <select value={relationship} onChange={(e) => setRelationship(e.target.value)}>
                <option value="">关系</option>
                <option value="老板">老板</option>
                <option value="同事">同事</option>
                <option value="合作伙伴">合作伙伴</option>
                <option value="兄弟">兄弟</option>
                <option value="朋友">朋友</option>
                <option value="情侣">情侣</option>
              </select>
            </label>
            <label>
              <input
                type="text"
                value={userMessage}
                onChange={(e) => setUserMessage(e.target.value)}
                placeholder="输入我想表达的..."
              />
            </label>
          </div>
          <button onClick={generateSuggestions} className="generate-button" disabled={isGenerating}>
            {isGenerating ? 'Generating...' : 'Generate Reply'}
          </button>
        </>
      )}
      <div className="suggestions-container">
        {suggestions.map(([type, reply], index) => (
          <div key={index} className="suggestion-item">
            <strong>{type}：</strong>
            <span>{reply}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
