// src/App.tsx
import './App.css';
import Home from "./pages/Home";
import React from "react";

const App: React.FC = () => {

  return (
    <>
      <Home/>
    </>
  );
};

export default App;
